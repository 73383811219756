/* Base styles */
:root {
  --primary-color: #1a1a1a;
  --secondary-color: #ff8c00;
  --accent-color: #ffd700;
  --gradient-start: #ff8c00;
  --gradient-end: #ffd700;
  --text-light: #FFFFFF;
  --text-dark: #1a1a1a;
  --background-light: #f8f8f8;
  --transition: all 0.3s ease;
}

body {
  background-color: var(--background-light);
  color: var(--text-dark);
}

/* Width of the scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

/* Track of the scrollbar */
::-webkit-scrollbar-track {
  background: var(--background-light);
}

/* Handle of the scrollbar */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--accent-color);
}

.navbar {
  position: fixed;
  width: 90%;
  max-width: 1400px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  transition: var(--transition);
}

.p-toolbar {
  background: linear-gradient(135deg, rgba(26, 26, 26, 0.95), rgba(51, 51, 51, 0.95)) !important;
  border: 1px solid rgba(255, 140, 0, 0.2) !important;
}

.floating-footer {
  font-size: 0.9rem;
  border-top: 1px solid #ccc;
}

.floating-footer a {
  text-decoration: none;
  color: #007ad9;
}

.floating-footer a:hover {
  text-decoration: underline;
}

.center-content-wrapper {
  display: block;
}

.menu-toggle-button {
  display: none;
}

.collapsed-menu {
  position: absolute;
  top: 4rem;
  left: 1rem;
  background: white;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.collapsed-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.collapsed-menu li {
  padding: 0.5rem 0;
  cursor: pointer;
}

.menu-toggle-button {
  display: none; /* Hidden by default on larger screens */
}

/* Media query for narrow screens */
@media (max-width: 768px) {
  .navbar {
    width: 95%;
    top: 10px;
  }

  .hero-content {
    padding: 1rem;
  }

  .contact-form {
    padding: 2rem;
    margin: 1rem;
  }
}

@media (min-width: 600px) {
  .menu-toggle-wrapper {
    display: none;
  }
}

/* Hero section styling */
.hero-section {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(135deg, var(--primary-color), #333333);
}

.hero-content {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

/* Form styling */
.contact-form {
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 3rem;
  max-width: 600px;
  margin: 0 auto;
}

.p-button {
  background: linear-gradient(135deg, var(--gradient-start), var(--gradient-end)) !important;
  border-color: var(--secondary-color) !important;
}

.p-button:hover {
  background: linear-gradient(135deg, var(--gradient-end), var(--gradient-start)) !important;
  border-color: var(--accent-color) !important;
}

/* Animation classes */
.fade-in {
  animation: fadeIn 1s ease-in;
}

.slide-up {
  animation: slideUp 0.8s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}